




















































































































































.modal--notes-create {
  .modal__wrapper {
    width: 836px;
    max-width: 100%;
  }

  .modal__lang {
    display: flex;
    margin-bottom: 26px;
  }

  .modal__lang-item {
    margin-right: 30px;
  }
}
