


























































































.select::v-deep {
  margin-bottom: 26px;

  .ant-select {
    width: 100%;
    height: auto;
    font-size: 15px;
    color: #1D293F;
  }

  .ant-select-selection {
    height: 50px;
    padding: 9px 14px;
  }

  .ant-select-selection__placeholder {
    color: #9ea3a7;
  }

  .select__error {
    display: none;
    opacity: 0;
    font-size: 13px;
    line-height: 17px;
    color: #F2323F;
    transition: all 200ms ease;
  }
}

.select--invalid::v-deep {
  .ant-select-selection {
    margin-bottom: 8px;
    background-color: #FFEAEA;
    border-color: #F2323F;
  }

  .select__error {
    display: block;
    opacity: 1;
  }
}

.select--search::v-deep {
  .ant-select {
    margin-bottom: 0;
  }

  .ant-select-selection {
    height: 40px;
    padding: 4px 14px;
    border: unset;
    background-color: #F4F7FC;
    color: #9C9EA3;
  }

  .ant-select-selection__placeholder {
    color: #9C9EA3;
  }
}
